<!--
 * @Author: 月魂
 * @Date: 2022-09-19 15:24:38
 * @LastEditTime: 2022-09-20 17:24:21
 * @LastEditors: 月魂
 * @Description: 直播
 * @FilePath: \silk-road\src\views\perform\live.vue
-->
<template>
  <div class="box">
    <van-nav-bar
      :title="getPerform.title"
      left-arrow
      @click-left="handleBack"
    />
    <div class="video">
      <!-- <video v-if="showVideo" class="video-cont" controls controlslist='nodownload' ref="video" :poster="getPerform.imgUrl">
        <source :src="getPerform.videoUrl">
      </video> -->

      <div class="video-cont" id="videoCont"></div>
      
      <div v-if="!showVideo">
        <img :src="getPerform.imgUrl" alt="" width="100%">
      </div>
    </div>
    <div class="perform">
      <div class="title">{{ getPerform.title }}</div>
      <div class="ele">
        <div class="team ele-item"><img src="http://image.wearetogether.com.cn/8th%2Fassets%2Findex%2Fdetail%2Fteam.png" alt="" class="icon">{{ getPerform.team }}</div>
        <div class="time ele-item"><img src="http://image.wearetogether.com.cn/8th%2Fassets%2Findex%2Fdetail%2Ftime.png" alt="" class="icon">{{ getTime }}</div>
        <!-- <div class="address ele-item"><img src="http://image.wearetogether.com.cn/8th%2Fassets%2Findex%2Fdetail%2Faddress.png" alt="" class="icon">{{ getPerform.address }}</div> -->
        <div class="address ele-item"><img src="http://image.wearetogether.com.cn/8th%2Fassets%2Findex%2Fdetail%2Ftime.png" alt="" class="icon">{{ statusMap[liveStatus] }}</div>
      </div>
      <div class="intro">
        <div class="intro-cont" v-for="(des, index) in getPerform.des" :key="index">{{ des }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import { QNRTPlayer } from "qn-rtplayer-web";
import dayjs from 'dayjs'
import vue from 'vue';
import { NavBar, Dialog, Notify } from 'vant'
import ImagePreview  from 'vant/lib/image-preview';
import 'vant/lib/image-preview/style';
import { handleBack } from '../../utils/util'
vue.use(ImagePreview).use(NavBar).use(Dialog).use(Notify)

export default {
  name: 'Live',
  components: {
  },
  data() {
    return {
      player: null,
      performId: null,
      statusMap: {
        beforeLive: '直播未开始',
        readyLive: '直播中',
        afterLive: '直播已结束'
      }
    }
  },
  computed: {
    getPerform() {
      let performNode = {};
      Object.values(this.$t('perform.list')).forEach(perform => {
        if (perform.id == this.performId) {
          performNode = perform
        }
      })
      
      return performNode
    },
    showVideo() {
      const performNode = this.getPerform
      let isShow = false;
      // 限时展播逻辑
      const now = dayjs().format('YYYY-MM-DD');
      if(performNode.showTime) {
        const showTime = Object.values(performNode.showTime)
        const liveTime = Object.values(performNode.liveTime)
        const startTime = now + ' ' + liveTime[0]
        const endTime = now + ' ' + liveTime[1]
        if(showTime.includes(now) && dayjs().isAfter(startTime) && dayjs().isBefore(endTime)) {
          isShow = true;
        } else {
          isShow = false;
        }
      }
      return isShow
    },
    liveStatus() {
      let status = 'beforeLive';
      const performNode = this.getPerform
      if(!performNode.showTime) return this.statusMap[status]
      const now = dayjs().format('YYYY-MM-DD');
      const showTime = Object.values(performNode.showTime)
      const liveTime = Object.values(performNode.liveTime)
      const startTime = now + ' ' + liveTime[0]
      const endTime = now + ' ' + liveTime[1]
      if (showTime.length > 1) {
        if(dayjs().isBefore(showTime[0])) {
          status = 'beforeLive'
        } else if (dayjs().isAfter(showTime[showTime.length - 1] + ' ' + liveTime[1])) {
          status = 'afterLive'
        } else {
          if(dayjs().isAfter(startTime) && dayjs().isBefore(endTime)) {
            status = 'readyLive'
          } else {
            status = 'beforeLive'
          }
        }
      } else if(showTime.length === 1) {
        if(dayjs().isBefore(showTime[0])) {
          status = 'beforeLive'
        } else if(dayjs().isAfter(showTime[0] + ' ' + liveTime[1])) {
          status = 'afterLive'
        } else {
          if(dayjs().isAfter(startTime) && dayjs().isBefore(endTime)) {
            status = 'readyLive'
          } else {
            status = 'beforeLive'
          }
        }
      }
      return status
    },
    getTime() {
      let time = ''
      let perform = this.getPerform
      if(perform.showTime) {
        const start = Object.values(perform.liveTime)[0]
        const showTime = Object.values(perform.showTime)
        showTime.forEach(item => {
          time += (item + ' ' + start)
        })
      }
      return time
    }
  },
  mounted() {
    this.performId = this.$route.params.id;
    // 禁止鼠标右键点击
    document.oncontextmenu = function() {
      return false
    }

    const status = this.liveStatus

    if(status === 'beforeLive') {
      // 直播未开始
      this.wxShareDiy({ title: this.$t('share.title'), desc: `丝艺节云平台即将直播: ${this.getPerform.title} - 时间： ${this.getTime}` }, { title: `丝艺节云平台即将直播: ${this.getPerform.title} - 时间： ${this.getTime}`, desc: this.$t('share.subTitle') })
    } else if(status === 'readyLive') {
      // 正在直播
      this.wxShareDiy({ title: this.$t('share.title'), desc: `丝艺节云平台正在直播: ${this.getPerform.title} - 国家： ${this.getPerform.country}` }, { title: `丝艺节云平台正在直播: ${this.getPerform.title} - 国家： ${this.getPerform.country}`, desc: this.$t('share.subTitle') })
    } else {
      // 直播结束
      this.wxShareDiy({ title: this.$t('share.title'), desc: `丝艺节云平台: ${this.getPerform.title} - 国家： ${this.getPerform.country}` }, { title: `丝艺节云平台: ${this.getPerform.title} - 国家： ${this.getPerform.country}`, desc: this.$t('share.subTitle') })
    }

    if (this.showVideo) {
      // 1. 创建 QNRTPlayer 对象
      const QNPlayer = new QNRTPlayer();
      // 2. 初始化配置信息
      QNPlayer.init({
        width: '100%',
        height: '100%',
        className: 'qiVideo',
        controls: true,
        objectFit: 'contain',
        playsinline: true,
        volumn: 1,
      });
      this.player = QNPlayer;
      // 3. 传入播放地址及容器，开始播放
      QNPlayer.play(
        this.getPerform.liveUrl, // 直播地址
        document.getElementById("videoCont")
      ).then(() => {
        document.querySelector('.qiVideo').style.zIndex = '111';
        QNPlayer.on('stats', () => {
          if (this.player.playerState === 4 && this.status === 'readyLive') {
            Dialog.alert({
              message: '直播中断, 请重新连接'
            }).then(() => {
              this.$router.replace('/live-list')
            })
          }
          // if (data.audioBitrate === 0 && data.videoBitrate === 0 && data.frameRate === 0 && this.player.playerState === 3) {
          //   this.status = 2;
          //   this.player.stop();
          //   Dialog.alert({ title: '提示', message: '主播已下播' })
          // }
        })
        QNPlayer.on('error', err => {
          switch(err.code) {
            case 20001: Notify('网络错误'); break;
            case 20002: Notify('请求服务鉴权失败'); break;
            case 20003: Notify('媒体流不存在'); break;
            case 20004: Notify('请求参数/服务端错误'); break;
            case 20005: Notify('SDP Description 错误'); break;
            case 20006: Notify('PeerConnection 连接异常断开'); break;
            case 30001: Notify('设备不支持 RTC/H264'); break;
          }
        })
      })
    }
    
  },
  destroyed() {
    if (this.player) this.player.release();
  },
  methods: {
    showImg(position) {
      if (window.innerWidth <= 1200) {
        ImagePreview({
          images: this.imgList,
          startPosition: position - 1
        });
      }
    },
    handleBack() {
      handleBack()
    }
  }
}
</script>

<style lang="less" scoped>
.box {
  min-height: 100vh;
  background: #fcf8ed;
  img {
    display: block;
  }
  .video {
    max-width: 1200PX;
    margin: auto;
    position: relative;
    .video-cont {
      display: block;
      object-fit: fill;
      width: 100%;
      height: 100%;
    }
    .sum {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      background-color: rgba(0, 0, 0, .5);
      padding: 4px 10px;
      display: flex;
      align-items: center;
      color: #fff;
      .sum-img {
        display: block;
        width: 24px;
        height: 100%;
        margin-right: 3px;
      }
      .sum-text {
        line-height: 1;
        font-size: 12px;
      }
    }
    video {
      display: block;
      height: 200px;
      background: #000;
      object-fit: contain;
    }
  }
  .perform {
    width: 100%;
    max-width: 1200PX;
    margin: auto;
    padding: 16px 0;
    font-family: SourceHanSerifCN-Bold;
    color: #4D4D4D;
    .title {
      padding: 0 16px;
      font-size: 21px;
      font-weight: bold;
    }
    .intro {
      width: 100%;
      padding: 16px;
      color: #999;
      font-family: SourceHanSansCN-Regular;
      font-size: 14px;
      line-height: 22px;
      border-radius: 25px 25px 0 0;
      border-top: 1px solid #F5F5F5;
      overflow: hidden;
      .intro-cont {
        margin-top: 8px;
        &:first-child {
          margin-top: 0;
        }
      }
    }
    .ele {
      padding: 0 16px;
      margin-top: 16px;
      font-size: 14px;
      display: flex;
      flex-wrap: wrap;
      font-family: SourceHanSansCN-Regular;
      .ele-item {
        width: 100%;
        display: flex;
        margin-top: 4px;
        align-items: center;
        &:first-child {
          margin-top: 0;
        }
        .icon {
          display: block;
          width: 16px;
          height: 16px;
          margin-right: 8px;
        }
      }
    }
    // 剧团介绍
    .troupe {
      .title {
        margin: 10px 0;
      }
      .review {
        display: block;
        width: 100%;
        margin-top: 8px;
      }
      .cont {
        display: flex;
        flex-wrap: wrap;
        padding: 10px;
        // background-image: linear-gradient(to right, #f39b03, #984081);
        color: #3a3a3a;
        background-color: #e9e9e9;
        .text {
          width: 100%;
          font-size: 14px;
          padding-top: 10px;
        }
      }
    }
  }
}
</style>
